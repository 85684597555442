import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import sanitizeHtml from 'sanitize-html';
import * as dataLayer from '../../../../store/actions/dataLayer';

const KEVEL_CDN = 'https://s.zkcdn.net/Advertisers/';

const config = {
  allowedTags: ['div', 'img', 'button', 'label', 'a'],
  allowedAttributes: {
    'div': ['id', 'class', 'style'],
    'img': ['id', 'src', 'alt', 'class', 'style', 'title'],
    'button': ['id', 'class', 'style'],
    'label': ['id', 'class', 'style'],
    'a': ['id', 'href', 'target', 'rel', 'class', 'style'],
  }
};

const CustomKevelAd = () => {
  const dispatch = useDispatch();
  const { customTemplate, data, clickUrl, impressionUrl } = useSelector((state) => state.app.kevel);

  const isImage = (string) => {
    return /(jpg|gif|png|jpeg|webp)$/i.test(string);
  };

  const replaceMacros = (match, key) => {
    if (isImage(data[key])) {
      return `${KEVEL_CDN}${data[key]}`;
    }

    return data[key] || match;
  };

  const prepareHTML = (html) => {
    const body = html.replace(/{{([-.\w]+)}}/g, replaceMacros);
    return sanitizeHtml(body, config);
  };

  const handleClick = () => {
    dispatch(dataLayer.processKameleoonGoal('custom-kevel-ad-click'));
  };

  if (!impressionUrl) {
    return null;
  }


  return (
    <div id="div0" className="kevel-wrapper kevel-custom-template">
      <a
        onClick={ handleClick }
        className="kevel-link"
        href={ clickUrl }
        target="_blank"
        rel="noreferrer"
        dangerouslySetInnerHTML={{ __html: prepareHTML(customTemplate) }}
      />
      <img
        src={ impressionUrl }
        className="kevel-pixel"
        alt="pixel image"
        width={0}
        height={0}
        border={0}
      />
    </div>
  );
};

export default CustomKevelAd;
