import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import * as dataLayer from '../../../../store/actions/dataLayer';

const ImageTemplate = () => {
  const dispatch = useDispatch();
  const { impressionUrl, clickUrl, imageUrl } = useSelector((state) => state.app.kevel);

  const handleClick = () => {
    dispatch(dataLayer.processKameleoonGoal('custom-kevel-ad-click'));
  };

  if (!impressionUrl) {
    return null;
  }

  return (
    <div id="div0" className="kevel-wrapper kevel-img-template">
      <a
        className="kevel-link"
        onClick={ handleClick }
        href={ clickUrl }
        target="_blank"
        rel="noreferrer"
      >
        <LazyLoadImage
          alt="ad"
          width={300}
          height={250}
          src={ imageUrl }
        />
      </a>
      <img
        src={ impressionUrl }
        className="kevel-pixel"
        alt="pixel image"
        width={0}
        height={0}
        border={0}
      />
    </div>
  );
};

export default ImageTemplate;
