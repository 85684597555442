import React from 'react';
import { useSelector } from 'react-redux';
import ImageKevelAd from './ImageKevelAd';
import CustomKevelAd from './CustomKevelAd';
import './styles.css';

const KevelAd = () => {
  const { template, customTemplate} = useSelector((state) => state.app.kevel);

  if (template === 'image') {
    return <ImageKevelAd />;
  }

  if (customTemplate && !template) {
    return <CustomKevelAd />;
  }

  return null;
};

export default KevelAd;
