import React, { useMemo, useEffect, useState, useContext } from 'react';
import {useDispatch} from 'react-redux';
import {
  getLocation,
  getPriceV2,
  getSellerName,
  getTitle,
  listingAttribute as getListingAttribute,
  showPriceCalculation,
  getCarouselImageUrls
} from '../../../../../utils/listingHelper';
import {
  getBoatUrl,
  imageUrlWithDimensions
} from '../../../../../utils/urlHelpers/boat';
import get from 'lodash/get';
import { getConfig, PortalConfigContext } from '../../../../../config/portal';

import {
  isManufacturerListing,
  isSafelyInitializedWithLayout,
  BREAKPOINTS,
  getBreakpoint,
  isTabletOrSmallLaptopResolution
} from '../../../../../utils/commonHelper';
import {
  calculateMonthlyPrice,
  isFinanceable
} from '../../../../../utils/trident';
import { Ad } from '@dmm/react-common-components';
import KevelAd from '../../../../../components/Ads/KevelAd';
import {
  ThreeColumnListing,
  DEFAULT_LISTING_IMAGE
} from '../ThreeColumnListing';
import PropTypes from 'prop-types';
import { listingPropTypes } from '../../../../../utils/commonPropTypes';
import { getAssetUrl } from '../../../../../utils/commonHelper';
import {
  useFeatureFlags
} from '../../../../../context/hooks/useFeatureFlags';
import { Cookies } from 'react-cookie';
import { HorizontalGallery } from '@dmm/lib-react-ui-components';

import {useTPPServices} from '../../../../../tppServices/tppDIHooks';
import { getMessages } from '../../../../../tppServices/translations/messages';
import { setGenericEvent } from '../../../../../store/actions/dataLayer';

export const ThreeColumnListings = ({
  sponsoredBoats,
  fuzzySponsoredBoats,
  listings,
  teaserRate,
  formatMessage: t,
  locale,
  openContactForm,
  adParams,
  tracking,
  setListingClick,
  userCurrency,
  cookies,
  isBranded,
}) => {
  let desktopAdCount = 0;
  let tabletSmallLaptopAdCount = 0;
  let position = 0;
  let standardPosition = 0;
  const MAX_DESKTOP_ADS = 4;

  const dispatch = useDispatch();
  const [device, setDevice] = useState('');
  const context = useContext(PortalConfigContext);
  const {boatsConstants} = useTPPServices();
  const messages = getMessages();
  const {
    LISTING_SPONSORED,
    LISTING_ENHANCED,
    LISTING_STANDARD,
    LISTING_MANUFACTURER,
    SPONSORED_CAROUSEL_LISTING,
    FIFTH_ROW_POSITION
  } = boatsConstants;
  const { featureFlagFuzzySponsored, featureFlagListingImageCarousel, featureFlagKevelAds } = useFeatureFlags(cookies);

  const enableSponsoredSearchExactMatch = get(
    context,
    'supports.enableSponsoredSearchExactMatch',
    false
  );
  const enableFuzzySponsoredSearch = get(
    context,
    'supports.enableFuzzySponsoredSearch',
    false
  ) && featureFlagFuzzySponsored;
  const enableListingImageCarousel = get(
    context,
    'supports.enableListingImageCarousel',
    false
  ) && featureFlagListingImageCarousel;
  const enableRandomizedSponsoredBoatsSearch = get(
    context,
    'supports.enableRandomizedSponsoredBoatsSearch',
    false
  );
  const enableSponsoredListingsCarrousel = get(
    context,
    'supports.enableSponsoredListingsCarousel',
    false
  );

  // Set initial breakpoint for correct device layout
  useEffect(() => {
    const breakpoint = isTabletOrSmallLaptopResolution()
      ? BREAKPOINTS.tabletOrSmallLaptop
      : getBreakpoint();
    setDevice(breakpoint);
  }, []);

  // Attach event listener to detect resize event
  useEffect(() => {
    const handleResize = () => {
      const breakpoint = isTabletOrSmallLaptopResolution()
        ? BREAKPOINTS.tabletOrSmallLaptop
        : getBreakpoint();
      setDevice(breakpoint);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isSearchMobileBoxAds = get(
    context,
    'supports.isSearchMobileBoxAds',
    false
  );

  const isKevelAdEnabled = get(
    context,
    'supports.isKevelAdEnabled',
    false
  ) && featureFlagKevelAds;

  const isTargetBlankEnabled = context?.supports?.isTargetBlankEnabled || false;
  const shouldOpenInNewTab = device === BREAKPOINTS.desktop && isTargetBlankEnabled;

  const getImageAlt = (listing) => {
    let listingAltText = getTitle(listing);

    const altTextVariant = get(
      getConfig(),
      'pages.searchResults.listingImage.altText.variant',
      0
    );
    if (altTextVariant === 1) {
      listingAltText = `${listing.make} ${listing.model}`;
    }

    return listingAltText;
  };

  const getImageUrl = (listing) => {
    const listingMedia = get(listing, 'media', []);
    const images = listingMedia.filter((media) => media.mediaType === 'image');
    const image = images[0];
    return image?.url ? imageUrlWithDimensions(image.url) : undefined;
  };

  const supportsMonthlyPayment = useMemo(
    () =>
      get(getConfig(), `languages.${locale}.supports.monthlyPayment`, false),
    []
  );
  const msgsMonthlyPayment = useMemo(
    () => messages.boatLoansContent.monthlyPaymentTooltip,
    []
  );
  const isDesktop = useMemo(() => isSafelyInitializedWithLayout('desktop'), []);

  const showPriceInfo = (listing) => {
    const enhanced = get(listing, 'featureType.enhanced', false);
    const isManufacturer = isManufacturerListing(listing.isOemModel);
    return enhanced || !isManufacturer;
  };

  const resolveMonthlyPrice = (listing) => {
    const loanAmount = parseFloat(listing.price?.type?.amount?.USD || 0);
    const termInMonths = loanAmount >= 50000 ? 240 : 180;

    return calculateMonthlyPrice(teaserRate, termInMonths, loanAmount);
  };

  const calculateTerm = (listing) => {
    const price = get(listing, 'price.type.amount.USD');
    return price && price >= 50000 ? 240 : 180;
  };

  const showMonthlyPrice = (listing) => {
    const price = listing.price?.type?.amount.USD;
    const hiddenPrice =
      get(listing, 'price.hidden') ||
      !listing.price?.type?.amount ||
      price <= 1;
    const financeable = isFinanceable(price, listing?.year);

    return (
      showPriceInfo(listing) &&
      !hiddenPrice &&
      supportsMonthlyPayment &&
      resolveMonthlyPrice(listing) &&
      financeable
    );
  };

  const showPrevPrice = (listing) =>
    showPriceInfo(listing) && !listing?.price?.hidden && listing?.previousPrice;

  const getLogo = (listing) => {
    return (
      get(listing.owner, 'logos.enhanced', '') ||
      get(listing.owner, 'logos.default', '')
    ).replace(/^http:\/\//, 'https://');
  };

  const getTags = (listing) => {
    const { hasAttribute, attributeText } = getListingAttribute(listing);
    if (!hasAttribute) {
      return showPrevPrice(listing) ? [{ text: 'Price Drop' }] : [];
    }
    return [{ text: attributeText }];
  };

  const getCPYBDataForListing = (listing) => {
    if (locale !== 'us' || !listing.cpybLogo) {
      return {};
    }
    const imageLogo = getAssetUrl('/assets/images/cpyb-logo.svg');
    return {
      imageLogo,
      imageLogoAlt: 'cpyb-logo'
    };
  };

  const MAX_NUMBER_OF_IMAGES = 5;
  const MIN_NUMBER_OF_IMAGES = 2;

  const setCarouselProps = (listing) => {
    const { images, imageCount } = getCarouselImageUrls(listing, MAX_NUMBER_OF_IMAGES, MIN_NUMBER_OF_IMAGES);
    if (!enableListingImageCarousel || isBranded || images?.length < MIN_NUMBER_OF_IMAGES) {
      return {};
    }

    return {
      Carousel: {
        prevClick: /* istanbul ignore next */ (event) => {
          event.preventDefault();
        },
        nextClick: /* istanbul ignore next */ (event) => {
          event.preventDefault();
        },
        items: images,
        classNames: {
          indicator: true
        },
        hideLeftArrowButtonOnFirstSlide: true,
        showArrowButtonsOnHover: (device === BREAKPOINTS.mobile || device === BREAKPOINTS.tablet) ? false : true,
        cta: {
          text: `View all ${imageCount} photos`,

        },
        disableResizeHandler: true
      },
    };
  };

  const getListingProps = (
    { listing, setListingClick, tracking, isSponsored = false, isExactMatchSponsored = false, position, sponsoredCarouselListing = false },//TODO: We should send new param for featured to diferentiate from first Featured Section & the second Sponsored
    currency = ''
  ) => {
    const location = getLocation(listing) || '';
    const sellerLocation = location ? ' | ' + location : '';
    const props = {
      targetBlank: shouldOpenInNewTab,
      tags: getTags(listing),
      onClick: () => {
        setListingClick(
          listing?.id,
          LISTING_STANDARD,
          tracking?.region,
          listing?.make
        );
      },
      name: getTitle(listing),
      image: getImageUrl(listing) || DEFAULT_LISTING_IMAGE,
      imageAlt: getImageAlt(listing),
      imageFetchPriority: position <= 6 ? true : false,
      price: showPriceInfo(listing)
        ? getPriceV2(listing, null, currency)
        : undefined,
      prevPrice: showPrevPrice(listing) ? '↓ Price Drop' : undefined,
      location,
      seller: getSellerName(listing.owner) + sellerLocation,
      monthlyPrice: showMonthlyPrice(listing) && showPriceCalculation(listing, context)
        ? {
            text: `${t(msgsMonthlyPayment.currencyCode)} $${resolveMonthlyPrice(
              listing
            )}/${t(msgsMonthlyPayment.monthAbbreviation)}${
              !isDesktop ? '*' : ''
            }`,
            tooltip: {
              content: t(messages.boatLoansContent.monthlyPaymentTooltip.text, {
                term: calculateTerm(listing),
                teaserRate
              }),
              title: `$${resolveMonthlyPrice(listing)}/${t(
                msgsMonthlyPayment.month
              )}`
            }
          }
        : undefined,
      tracking,
      listing,
      type: LISTING_STANDARD,
      defaultImage: DEFAULT_LISTING_IMAGE,
      ...getCPYBDataForListing(listing),
      ...setCarouselProps(listing)
    };

    if (isSponsored) {
      props.image = getImageUrl(listing);
      props.tags = [{ text: isExactMatchSponsored ? 'Featured' : 'Sponsored' }];
      props.onClick = () =>
        setListingClick(
          listing.id,
          LISTING_SPONSORED,
          tracking?.region,
          listing.make
        );
      props.logo = getLogo(listing) || undefined;
      props.logoAlt = listing?.owner?.name ?? '';
      props.seller = (listing?.owner?.name ?? '') + sellerLocation;
      props.contact = {
        onClick: (event) => {
          if (isExactMatchSponsored) {
            event.preventDefault();
            openContactForm(listing);
          } else {
            window.open(getBoatUrl(listing), shouldOpenInNewTab ? '_blank' : '_self');
          }
        },
        text: t(messages.searchResults.listing.requestInfo)
      };
      props.classNames = { sponsored: true };
      props.type = sponsoredCarouselListing ? SPONSORED_CAROUSEL_LISTING : LISTING_SPONSORED;
      props.price = getPriceV2(listing, null, currency);
      props.prevPrice = undefined;
      props.monthlyPrice = undefined;
    } else if (get(listing, 'featureType.enhanced', false)) {
      props.logo = getLogo(listing) || undefined;
      props.logoAlt = listing?.owner?.name ?? '';
      props.contact = {
        onClick: (event) => {
          event.preventDefault();
          openContactForm(listing);
        },
        text: t(messages.searchResults.listing.requestInfo)
      };
      props.type = LISTING_ENHANCED;
      props.onClick = () =>
        setListingClick(
          listing.id,
          LISTING_ENHANCED,
          tracking?.region,
          listing.make
        );
    } else if (get(listing, 'isOemModel', false)) {
      props.type = LISTING_MANUFACTURER;
      props.onClick = () => {
        setListingClick(
          listing.id,
          LISTING_MANUFACTURER,
          tracking?.region,
          listing.make
        );
        window.open(getBoatUrl(listing), '_self');
      };
      props.logo = getLogo(listing) || undefined;
      props.logoAlt = listing?.owner?.name ?? '';
      props.hiddenPriceMessage = t(messages.pricing.request);
      props.manufacturerListingText = t(
        messages.dealerContact.manufacturerListing
      );
      props.seller = `${getSellerName(listing.owner)} | ${t(
        messages.dealerContact.manufacturerListing
      )}`;
    }
    return props;
  };

  const setDesktopLayout = (
    listingArr,
    listing,
    position,
    standardPosition,
    index,
    currency = '',
    sponsored = false
  ) => {
    if (
      desktopAdCount < MAX_DESKTOP_ADS &&
      (index === 4 || (index - 4) % 5 === 0)
    ) {
      let adSlot =
        adParams[`${'inlineBox'}${(desktopAdCount += 1)}${'Params'}`];

      const isKevelAd = adSlot?.adId === 'div-gpt-ad-inline-box-2' && isKevelAdEnabled;

      listingArr.push(
        <span key={`desktop${listing.id}`} className="column-ads">
          { isKevelAd ? <KevelAd /> : <Ad {...adSlot} /> }
        </span>
      );
      position += 1;
      standardPosition += 1;
    }
    const props = getListingProps(
      { listing, setListingClick, tracking, sponsored, position },
      currency
    );
    listingArr.push(
      <ThreeColumnListing key={listing.id} {...props} position={position} />
    );
    return listingArr;
  };

  const setTabletOrSmallLaptopLayout = (
    listingArr,
    listing,
    position,
    standardPosition,
    index,
    currency,
    sponsored
  ) => {
    if (index === 3 || index === 12) {
      let adSlot1 =
        adParams[`${'inlineBox'}${(tabletSmallLaptopAdCount += 1)}${'Params'}`];
      let adSlot2 =
        adParams[`${'inlineBox'}${(tabletSmallLaptopAdCount += 1)}${'Params'}`];
      listingArr.push(
        <span key={`desktop${listing.id}`} className="column-ads tablet-ads">
          <Ad {...adSlot1} />
          <Ad {...adSlot2} />
        </span>
      );
      position += 1;
      standardPosition += 1;
    }
    const props = getListingProps(
      { listing, setListingClick, tracking, sponsored, position },
      currency
    );
    listingArr.push(
      <ThreeColumnListing key={listing.id} {...props} position={position} />
    );
    return listingArr;
  };

  const setMobileLayout = (listingArr, listing, index) => {
    if (isSearchMobileBoxAds && index === 3) {
      listingArr.push(
        <div className="mobile-box" key={`mobile${listing.id}`}>
          <Ad {...adParams.mobileBox1Params} />
        </div>
      );
    }

    if (isSearchMobileBoxAds && index === 9) {
      listingArr.push(
        <div className="mobile-box" key={`mobile${listing.id}`}>
          <Ad {...adParams.mobileBox2Params} />
        </div>
      );
    }

    if (isSearchMobileBoxAds && index === 14) {
      listingArr.push(
        <div className="mobile-box" key={`mobile${listing.id}`}>
          <Ad {...adParams.mobileBox3Params} />
        </div>
      );
    }

    return listingArr;
  };

  const currency = userCurrency;

  const getFeaturedBoats = () => {
    return (
      <div className="container sponsored" data-testid="featured-boats">
        {sponsoredBoats.reduce((listingArr, sponsoredBoat, index) => {
          position += 1;
          if (device === BREAKPOINTS.mobile) {
            if (!isSearchMobileBoxAds && index === 1) {
              listingArr.push(
                <div
                  key={`mobile${sponsoredBoats.id}`}
                  className="sticky-mobile-ad"
                >
                  <Ad {...adParams.mobileLeaderboard1Params} />
                </div>
              );
            }
            const props = getListingProps(
              {
                listing: sponsoredBoat,
                setListingClick,
                tracking,
                isSponsored: true,
                isExactMatchSponsored: enableSponsoredSearchExactMatch,
                position: position
              },
              currency
            );
            listingArr.push(
              <ThreeColumnListing
                key={sponsoredBoat.id}
                {...props}
                position={position} />
            );
            return listingArr;
          }
          const props = getListingProps(
            {
              listing: sponsoredBoat,
              setListingClick,
              tracking,
              isSponsored: true,
              isExactMatchSponsored: enableSponsoredSearchExactMatch,
              position: position
            },
            currency
          );
          listingArr.push(
            <ThreeColumnListing
              key={sponsoredBoat.id}
              {...props}
              position={position} />
          );
          return listingArr;
        }, [])}
      </div>);
  };

  const getViewWithoutListingsCarrousel = () => {
    return (
      <>
        <div className="container" data-testid="3-col-listings-container">
          {listings.reduce((listingArr, listing, index) => {
            if (standardPosition === FIFTH_ROW_POSITION && (enableFuzzySponsoredSearch || enableRandomizedSponsoredBoatsSearch) && fuzzySponsoredBoats?.length > 0) {
              listingArr = listingArr.concat(fuzzySponsoredBoats.reduce((listingArr, fuzzySponsoredBoat) => {
                position += 1;
                standardPosition += 1;
                const props = getListingProps(
                  {
                    listing: fuzzySponsoredBoat,
                    setListingClick,
                    tracking,
                    isSponsored: true,
                    isExactMatchSponsored: false,
                    position: position,
                    height: 14
                  },
                  currency
                );
                listingArr.push(
                  <ThreeColumnListing
                    key={fuzzySponsoredBoat.id}
                    {...props}
                    position={position} />
                );
                return listingArr;
              }, []));
            } else {
              position += 1;
              standardPosition += 1;
            }

            if (device === BREAKPOINTS.tabletOrSmallLaptop) {
              return setTabletOrSmallLaptopLayout(
                listingArr,
                listing,
                position,
                standardPosition,
                index,
                currency
              );
            }
            if (!sponsoredBoats.length && index === 1) {
              listingArr.push(
                <div
                  key={`mobile${sponsoredBoats.id}`}
                  className="sticky-mobile-ad"
                >
                  <Ad {...adParams.mobileLeaderboard1Params} />
                </div>
              );
            }
            setMobileLayout(listingArr, listing, index);
            return setDesktopLayout(listingArr, listing, position, standardPosition, index, currency);
          }, [])}
        </div>
      </>
    );
  };

  const getSponsoredListingsCarousel = (fuzzySponsoredBoats, position, standardPosition, setListingClick, tracking) => {
    return fuzzySponsoredBoats.map((fuzzySponsoredBoat) => {
      position += 1;
      standardPosition += 1;
      const props = getListingProps(
        {
          listing: fuzzySponsoredBoat,
          setListingClick,
          tracking,
          isSponsored: true,
          isExactMatchSponsored: false,
          position,
          sponsoredCarouselListing: true
        },
        currency
      );
      return (<ThreeColumnListing key={fuzzySponsoredBoat.id} {...props} position={position} />);
    });
  };

  const handleScrollDirection = (direction) => {
    dispatch(setGenericEvent('sponsored carousel event', `carousel scroll ${direction}`, `scroll ${direction}`, '', 'scroll'));
  };

  const getViewWithListingsCarrousel = () => {
    const sliceBreakPoint = device === BREAKPOINTS.tabletOrSmallLaptop ? 9 : 10;
    const firstChunk = listings.slice(0, sliceBreakPoint);
    const secondChunk = listings.slice(sliceBreakPoint);
    return (
      <>
        <div className="container" data-testid="3-col-listings-container">
          {firstChunk.reduce((listingArr, listing, index) => {
            position += 1;
            standardPosition += 1;
            if (device === BREAKPOINTS.tabletOrSmallLaptop) {
              return setTabletOrSmallLaptopLayout(
                listingArr,
                listing,
                position,
                standardPosition,
                index,
                currency
              );
            }
            if (!sponsoredBoats.length && index === 1) {
              listingArr.push(
                <div
                  key={`mobile${sponsoredBoats.id}`}
                  className="sticky-mobile-ad"
                >
                  <Ad {...adParams.mobileLeaderboard1Params} />
                </div>
              );
            }
            setMobileLayout(listingArr, listing, index);
            return setDesktopLayout(listingArr, listing, position, standardPosition, index, currency);
          }, [])}
        </div>
        <div className="container carousel" data-testid="sponsored-listings-carousel">
          <HorizontalGallery
            title={t(messages.searchResults.listingsCarrousel.sponsored.title)}
            subtitle={t(messages.searchResults.listingsCarrousel.sponsored.subtitle)}
            hideArrowButtons={device === BREAKPOINTS.mobile}
            leftButtonAttributes={{
              'data-reporting-click-internal-link-type': 'click',
              'data-reporting-click-internal-link-id': 'move carousel left'
            }}
            rightButtonAttributes={{
              'data-reporting-click-internal-link-type': 'click',
              'data-reporting-click-internal-link-id': 'move carousel right'
            }}
            { ...(device === BREAKPOINTS.mobile && { onScrollDirection: handleScrollDirection }) }
          >
            {getSponsoredListingsCarousel(fuzzySponsoredBoats, position, standardPosition, setListingClick, tracking)}
          </HorizontalGallery>
        </div>
        <div className="container" data-testid="3-col-listings-container">
          {secondChunk.reduce((listingArr, listing, index) => {
            position += 1;
            standardPosition += 1;
            if (device === BREAKPOINTS.tabletOrSmallLaptop) {
              return setTabletOrSmallLaptopLayout(
                listingArr,
                listing,
                position,
                standardPosition,
                index,
                currency
              );
            }
            setMobileLayout(listingArr, listing, index);
            return setDesktopLayout(listingArr, listing, position, standardPosition, index, currency);
          }, [])}
        </div>
      </>
    );
  };

  const renderWithCarrousel = enableSponsoredListingsCarrousel && fuzzySponsoredBoats?.length > 0;
  return (
    <>
      {sponsoredBoats && sponsoredBoats.length >= 3 && getFeaturedBoats()}
      {!renderWithCarrousel && getViewWithoutListingsCarrousel()}
      {renderWithCarrousel && getViewWithListingsCarrousel()}
    </>
  );
};

ThreeColumnListings.propTypes = {
  sponsoredBoats: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  fuzzySponsoredBoats: PropTypes.arrayOf(listingPropTypes),
  listings: PropTypes.arrayOf(listingPropTypes),
  teaserRate: PropTypes.number,
  formatMessage: PropTypes.func,
  locale: PropTypes.string,
  openContactForm: PropTypes.func,
  adParams: PropTypes.object,
  setListingClick: PropTypes.func,
  tracking: PropTypes.shape({
    region: PropTypes.string,
    page: PropTypes.string,
    pageSize: PropTypes.string,
    setProductImpression: PropTypes.func
  }),
  userCurrency: PropTypes.string,
  cookies: PropTypes.instanceOf(Cookies),
  isBranded: PropTypes.bool,
  Carousel: PropTypes.object
};
