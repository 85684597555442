import get from 'lodash/get';
import concat from 'lodash/concat';
import createHttp from '@dmm/create-http';
import { getConfig } from '../../config/portal';
import { getIpAddress, getUserAgent } from '../../server/middleware/services.js';
import * as types from '../constants';
import { formatMultiArrayUrlToKeywords } from '../../utils/multiFacetHelper';

const setKevelAd = data => ({
  type: types.KEVEL_SET_AD,
  data
});

export const getAd = (cookies, params) => async (dispatch) => {
  if (isBotOrCrawler(getUserAgent())) {
    return;
  }

  try {
    const { url, siteId, networkId, zoneIds } = get(getConfig(), 'kevel', {});
    const apiClient = createHttp({ baseURL: url });

    const typeClassKeywords = formatMultiArrayUrlToKeywords(params?.multiFacetedBoatTypeClass, true);
    const makeModelKeywords = formatMultiArrayUrlToKeywords(params?.multiFacetedMakeModel);
    const keywords = concat(typeClassKeywords, makeModelKeywords);

    const key = cookies.get('kevelUserKey');
    // TODO: (in GDPR ticket)
    // - replace __GDPRCookie__ with correct name to get user's consent value.
    // - Add user's ip address to payload. For users from Europe consent.gdpr is false by default.
    const gdpr = cookies.get('__GDPRCookie__') || false;

    const payload = {
      placements: [{
        divName: 'div0',
        networkId: networkId,
        siteId: siteId,
        zoneIds: zoneIds,
        adTypes: [5] // 300 x 250
      }],
      keywords,
      consent: {
        gdpr: !!gdpr
      },
      ip: getIpAddress(),
      enableBotFiltering: true,
      ...(key && { user: { key }}),
    };

    const res = await apiClient.post('/', payload);

    if (!key && gdpr) {
      const KEVEL_USER_KEY = 'kevelUserKey';
      const cookieValue = res.data?.user?.key;
      const options = { value: cookieValue, path: '/' };

      cookies.set(KEVEL_USER_KEY, cookieValue, options);
    }

    return dispatch(setKevelAd(res.data));

  } catch (err) {
    // TODO: Implement error logic
    return;
  }
};

const isBotOrCrawler = (userAgent) => {
  // Most common user agents coming from bots and crawlers on our services
  return /bot|crawl|spider|bg-metrics|jaunt\/1|ias-(or|va)|facebookexternalhit/i.test(userAgent);
};
